import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SearchOrders from './components/SearchOrders';
import SearchOrderResults from "./components/SearchOrderResults";
import './App.css';

function App() {
  return (
    <>

      <nav className="navbar navbar-light" style={{ backgroundColor: 'rgb(0,46,93)' }}>
        <Container>
          <a className="navbar-brand" href="#" style={{ color: 'rgb(255,255,255)' }}>Rubber Fab Completed Order Tracker</a>
        </Container>
      </nav>

      <Container style={{ minHeight: 'calc(100vh - 50px)'}}>
        <Routes>
          <Route path="/" element={<SearchOrders />} />
          <Route path="/SearchOrderResults" element={<SearchOrderResults />} />
        </Routes>
      </Container>

    </>
  );
}

export default App;
