import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import { SearchOrderResultsSet } from '../interfaces/SearchOrderResultsSet';

const SearchOrderResults: React.FC = () => {
  const navigate = useNavigate();

  // @ts-ignore
  const location: any = useLocation();
  const { state } = location;

  const navigateToTracking = (obj: any) => {
    const shipper = obj.shippingMethod.trim()

    if (shipper.slice(0, 3) === 'UPS') {
      window.open(`https://www.ups.com/track?loc=null&tracknum=${obj.trackingNumber}&requester=WT/`);
    } else if (shipper.slice(0, 5) === 'FEDEX') {
      window.open(`https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${obj.trackingNumber}`);
    } else {
      alert('shipper not recognized');
    }
  };

  // @ts-ignore
  return (
    <>

      <h1 style={{
        margin: '15px 0',
        color: '#555555',
        font: '46px Oswald, "sans-serif"',
        fontStretch: 'condensed',
      }}>Order Tracking Report</h1>

      <br />

      <div className="container">
        <div className="row mb-2">
          <div className="col">
            <button type="button" className="btn btn-outline-dark float-right" onClick={() => {navigate('/',  { replace: false });}}>Start Over</button>
          </div>
        </div>
      </div>

      <table className="table table-bordered table-hover table-sm" style={{ backgroundColor: 'rgb(255,255,255)' }}>
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell">Invoice #</th>
            <th className="d-none d-sm-table-cell">Customer PO #</th>
            <th className="d-none d-sm-table-cell">Sales Order #</th>
            <th className="d-none d-sm-table-cell">Shipping Method</th>
            <th className="d-none d-sm-table-cell">Tracking Code</th>
            <th className="d-none d-sm-table-cell">Part Number</th>
            <th className="d-none d-sm-table-cell">Zip Code</th>
            <th className="d-none d-sm-table-cell">Quantity</th>
            <th className="d-none d-sm-table-cell">Quote #</th>
          </tr>
        </thead>
        <tbody>
          {state.orders.map((item: SearchOrderResultsSet, i: number) =>
            <tr key={i} style={{ cursor: 'pointer' }} onClick={() => {navigateToTracking(item)}}>
              <td className="d-none d-sm-table-cell">{item.invoiceNumber}</td>
              <td className="d-none d-sm-table-cell">{item.custPoNumber}</td>
              <td className="d-none d-sm-table-cell">{item.orderNbr}</td>
              <td className="d-none d-sm-table-cell">{item.shippingMethod}</td>
              <td className="d-none d-sm-table-cell">{item.trackingNumber}</td>
              <td className="d-none d-sm-table-cell">{item.itemNumber}</td>
              <td className="d-none d-sm-table-cell">{item.zipCode}</td>
              <td className="d-none d-sm-table-cell">{item.quantity}</td>
              <td className="d-none d-sm-table-cell">{item.quoteNbr}</td>
            </tr>
          )}
        </tbody>
      </table>

    </>
  );

}

export default SearchOrderResults;
