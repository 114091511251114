import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Spinner} from "react-bootstrap";

interface searchFormTemplate {
  orderType: string;
  orderNumber: string;
  zipCode: string;
}

const SearchOrders = () => {
  const navigate = useNavigate();

  const searchFormInit: searchFormTemplate = {
    orderType: '0',
    orderNumber: '',
    zipCode: ''
  }

  const [searchForm, setSearchForm] = useState<searchFormTemplate>(searchFormInit);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const searchOrders = (url: string) => {
    setLoading(true);
    axios.get(url)
      .then(res => {
        const orders = res.data;
        setLoading(false);

        if (orders.length > 0) {
          navigate(`/SearchOrderResults`, { state: { orders: res.data }});
        } else {
          setErrorMsg('Could Not Find Any Matching Orders');
          setTimeout(() => setErrorMsg(''), parseInt(process.env.REACT_APP_WARNING_TIMEOUT || '0'));
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }

  const submitSearch = (e: any) => {
    e.preventDefault();
    let url = '';
    if (searchForm.orderType === '1') {
      url = process.env.REACT_APP_API_URI + `/Order/GetOrderByCustPoNumberAndZipCode?custPoNumber=${searchForm.orderNumber}&zipCode=${searchForm.zipCode}`;
    } else if (searchForm.orderType === '2') {
      url = process.env.REACT_APP_API_URI + `/Order/GetOrderBySopNumberAndZipCode?sopNumber=${searchForm.orderNumber}&zipCode=${searchForm.zipCode}`;
    }
    searchOrders(url);
  };

  return (
    <>

      <Container>
        <h1 style={{
          margin: '15px 0',
          color: '#555555',
          font: '46px Oswald, "sans-serif"',
          fontStretch: 'condensed',
          borderBottom: '1px solid #ccc'
        }}>Order Tracker</h1>
        <h4 style={{
          fontFamily: '"Oswald","sans-serif"',
          fontSize: '24px',
          fontWeight: '600',
          lineHeight: '33px',
          letterSpacing: '.4px',
          color: '#333'
        }}>This page allows you to display basic information about your completed order. You will need the following information:</h4>

        <ol>
          <li><strong><em>Either</em></strong>&nbsp;the purchase order number&nbsp;<strong><em>or&nbsp;</em></strong>the Rubber Fab’s Sales order number.</li>
          <li>The postal or ZIP code for the ShipTo (Drop Ship) of the ordering location.</li>
        </ol>
      </Container>

      <Container className="shadow-sm mt-3 p-3">
        <Form onSubmit={submitSearch}>

          <Row className="align-items-end">

            <Col md={3}>
              <div className="form-floating">
                <Form.Control
                  as="select"
                  id="orderType"
                  value={searchForm.orderType}
                  onChange={(event) => { setSearchForm({...searchForm, orderType: event.target.value}) }}
                  required
                  autoComplete="off" >
                  <option value="0">Select Type</option>
                  <option value="1">PO #</option>
                  <option value="2">Order #</option>
                </Form.Control>
                <label className="form-label" htmlFor="orderType">Type</label>
              </div>
            </Col>

            <Col md={3} className="pl-md-5">
              <div className="form-floating">
                <Form.Control
                  type="text"
                  id="orderNumber"
                  value={searchForm.orderNumber}
                  onChange={(event) => { setSearchForm({...searchForm, orderNumber: event.target.value}) }}
                  required
                  autoComplete="off" />
                <Form.Label htmlFor="orderNumber">PO/Order #</Form.Label>
              </div>
            </Col>

            <Col md={3} className="pl-md-5">
              <div className="form-floating">
                <Form.Control
                  type="text"
                  id="zipCode"
                  value={searchForm.zipCode}
                  onChange={(event) => { setSearchForm({...searchForm, zipCode: event.target.value}) }}
                  required
                  autoComplete="off" />
                <Form.Label htmlFor="zipCode">Ship To Zip Code</Form.Label>
              </div>
            </Col>

            <Col md={3} className="text-md-center">
              <span>
                <button type="submit" className="btn btn-outline-dark mr-md-1" disabled={(searchForm.orderType === '0') || (searchForm.orderNumber.trim() === '') || (searchForm.zipCode.trim() === '')}>Get Status</button>
                <button type="button" className="btn btn-outline-dark" onClick={() => { setSearchForm(searchFormInit); }}>Clear</button>
              </span>
            </Col>

          </Row>

        </Form>
      </Container>

      {loading &&
      <Spinner
          as="span"
          animation="grow"
          role="status"
          aria-hidden="true" />
      }

      {(errorMsg !== '') &&
        <Container className="mt-2">
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
        </Container>
      }

    </>
  );

}

export default SearchOrders;
